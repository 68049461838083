import React from "react";
import { Link } from "@reach/router";

const ToolsBreadcrumb = () => {
  return (
    <div className="flex max-w-7xl mx-auto gap-3 px-4 2xl:px-0 items-center text-base text-[#494949] font-opensans font-semibold">
      <Link to="/tools" className="flex gap-3 items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
        >
          <path
            d="M7 13L1 7L7 1"
            stroke="#575757"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span className="text-[#B0B0B0]">Tools</span>
      </Link>
    </div>
  );
};

export default ToolsBreadcrumb;

export const endpoints = {
  comments: {
    getComments: "/api/v1/comments?seoid={id}&page={pagenumber}",
    postComments: "/api/v1/comment/create",
  },
  conference: {
    getConferenceData:
      "/api/v1/get-conference?country={country}&conf_from={Fromdate}&conf_to={ToDate}",
    addConference: "/api/v1/add-conference",
    getRecentConference: "/api/v1/get-conference",
    getConference: "/get-conference-info?id={id}",
  },
  forms: {
    contact: "/api/v1/contactus",
    subscribe: "/api/v1/submit-subscribe",
    midform: "/api/v1/mid-contact-us",
    getIntTouchLocation: "/api/v1/location-get-in-touch",
    getInTouch: "/api/v1/get-in-touch",
    locationGetInTouch: "/api/v1/location-get-in-touch",
    submitSubscribe: "/api/v1/save-quote",
    tailoredQuote: "/api/v1/tailored-quote",
    payAfterDelivery: "/api/v1/pay-after-delivery",
    documentUpload: "/api/v1/document-translation",
    addInterpretation: "/api/v1/interpretation-service",
    videoTranslation: "/api/v1/video-translation",
    forgetPassword: "/api/v1/user-forgot-password",
    changePassword: "/api/v1/new-password",
    popup: "/api/v1/popup-lead",
    youtube: "/api/v1/youtube-video-translation",
    helpMyself: "/api/v1/help-myself",
    helpEverybody: "/api/v1/help-everybody",
    audioTranslation: "/api/v1/audio-translation",
    summaryTool: "/api/v1/summary-tool",
    transcriptionServices: "/api/v1/transcription-services",
    languageIdentifier: "/api/v1/language-detection",
    topicIdentifier:"api/v2/tool-topic-identification",
    anonymizationText: "/api/v2/tool-data-anonymization",
    aiTools:"/api/v2/ai-tools",
     
  },
  quotes: {
    translate: "/api/v1/get-language",
    postQuoteLanguages: "/api/v1/get-a-quote",
    postUploadFile: "/api/v1/upload-document",
    deleteFile: "/api/v1/delete-document?id={9}",
    getFinalQuote: "/api/v1/get-final-quote",
    placeOrder: "/api/v1/place-order",
    all_Data: "/api/v1/get-quote-info?quote_id=1",
    checkoutAsGuest: "/api/v1/continue-as-guest",
  },
  reviews: {
    getReview: "/api/v1/get-reviews?page={pagenumber}",
    postReview: "/api/v1/post-review",
  },
  facts: {
    getFacts: "/api/v1/get-language-facts?page={pagenumber}",
    postFacts: "/api/v1/post-language-facts",
  },
  tips: {
    getTips: "/api/v1/get-tips?page={pagenumber}",
    postTips: "/api/v1/post-tip",
  },
  auth: {
    signUp: "/api/v1/user-register",
    signIn: "/api/v1/user-login",
    LogIn: "/api/v1/user-login",
    Register: "/api/v1/user-register",
    dashboard: "/api/v1/get-quote-by-user",
    changeDashPassword: "/api/v1/user-change-password",
    oldProjects: "/api/v1/get-my-projects",
    translatorRegister: "/api/v1/translator-register",
  },
  coupons: {
    applyPromoCode: "/api/v1/apply-promocode",
    removePromoCode: "/api/v1/remove-promocode",
  },
  payment: {
    payment_by_card: "/api/v1/payment-bluesnap",
    payment_by_paypal: "/api/v1/payment-paypal",
  },
  projectTimer: {
    getTimer: "/api/v1/potential-project-timer?pno=164672",
  },
  scratchCard: {
    reward: "/api/v1/reward-used",
  },
  videos: {
    getVideos: "/api/v2/get-content-for-videos-page",
  },
};
